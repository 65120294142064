.App {
  text-align: center;
}
h4 {
  display: block;
  text-align: center;
}
.Menu {
  margin: 0;
  background-color: darkslateblue;
  display: flex;
  flex-basis: 4fr;
  flex-flow: row;
  justify-content: left;
  align-items: baseline;
}
.Menu > a {
  color: white;
  padding: 0.8em 1em;

  background-color: rgba(104, 104, 104, 0.4);

  text-decoration: none;
  transition: background-color 0.3s;
}
.Menu > a:hover {
  background-color: rgba(104, 104, 104, 0.8);
}
.Menu > a:last-child {
  margin-left: auto;
}

.Dashboard {
  display: flex;
  flex-direction: column;
}
.Dashboard > div {
  min-width: 250px;
  min-height: 150px;
  border: 1px solid black;
  margin: 10px;
}
.akttippDashTippscheine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Tippschein {
  margin: 0.5em;
  width: 3em;
  height: 6em;
  border: 1px solid black;
  border-radius: 3px;
  box-shadow: 0 1px 5px silver;
  background-color: #ccc;
}
.Kugel {
  margin: 0.25em auto;
  width: 1.4em;
  height: 1.4em;
  border-radius: 4px;
  background-color: orange;
  border: 2px solid black;
  text-align: center;
}
.moneyDash {
  background: url("./money.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moneyDashGeldschein {
  padding: 0 2em 0.5em 2em;
}
.cssGeldschein {
  width: 100%;
  min-width: 100px;
  min-height: 50px;
  line-height: 50px;
  text-align: center;
}
.cssGeldscheinText {
  font-size: 1.2em;
  font-weight: 700;
  text-shadow: 0 0 10px #fff;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.3em 1em;
  border-radius: 10px;
}
.cssNeuerTippscheinGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 0.5em;
  padding: 0 1em;
  height: 60vh;
}
.cssZahl {
  font-size: 120%;
  padding: 1em;
  background-color: rgba(255, 165, 0);
  border: 1px solid black;
  transition: background-color 0.2s;
}
.cssZahl:hover {
  background-color: rgba(255, 165, 0, 0.8);
}
.cssZahlChosen {
  font-size: 120%;
  background-color: rgba(144, 238, 144, 1);
  padding: 1em;
  border: 1px solid black;
  transition: background-color 0.2s;
}
.cssZahlChosen:hover {
  background-color: rgba(144, 238, 144, 0.8);
}
.cssTippscheinSubmit {
  grid-column: span 3;
  font-size: 120%;
  padding: 1em;
  background-color: seagreen;
  border: 1px solid black;
  transition: background-color 0.4s;
  cursor: pointer;
}
.cssTippscheinSubmit:hover {
  background-color: greenyellow;
}
.cssTippscheinInfo {
  border: 1px solid black;
  background-color: rgba(104, 204, 155, 0.6);
}
input[type="text"],
input[type="password"] {
  padding: 0.4em;
  margin: 0.2em;
  width: 80%;
}
.sF {
  margin: 0.4em;
  padding: 0.4em 0.8em;
  background-color: lightblue;
  border: 1px solid black;
  border-radius: 2px;
  box-shadow: 0 0 2px black;
  transition: background-color 0.2s;
}
.sF:hover {
  background-color: lightcyan;
}

.myWinsDash {
  font-size: 80%;
  text-align: left;
  padding: 0 1em;
}
.myWinsDashWin {
}

.span3 {
  grid-column: 1/-1;
}
.btnreload {
  margin: 0.5em auto;
  width: 60%;
  padding: 1em;
  font-size: 0.8em;
  background-color: #b43000;
  border: 1px solid black;
  border-radius: 3px;
  font-weight: 700;
  color: white;
  box-shadow: 0 0 2px blue;
  transition: background-color 0.4s;
}
.btnreload:hover {
  background-color: #e43000;
}
